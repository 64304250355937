import { stateList } from "../../../../../../Components/GST/stateData"
import { invoiceSummaryCalc } from "../../../../../../Recoil/Invoice/HelperFunc"
import { getDiscountedPrice, getPriceOnRateAndTax, getRateOnPrice, getTaxOnRate } from "../../../../../General/DiscountCals"
import { getFormatedDate } from "../../../../../General/GetDateTime"
import { iNRWords } from "../../../../../General/numberToWords"
import { numToFixed, numToFixed2, numToIfFixed2, numToIfFixed3 } from "../../../../../General/numManipulation"
import { getLenOfObjBool, objReduce } from "../../../../../General/objectManipulation"
import { splitIntoLines, writeTextForPDF } from "../../../PDFFunctions"
import { invoicePageFooter, invoicePageHeader, invoicePageWaterMark } from "../PDFInvoiceHelperFunc"

const itemsSizeWithFooter = {
    single: 16,
    mid: 28,
    last: 16,
    namesize: 15,
}

const itemsSizeWithOutFooter = {
    single: 20,
    mid: 28,
    last: 20,
    namesize: 15,
}

const itemsSizeNonGST = {
    single: 17,
    mid: 17,
    last: 16,
    namesize: 25,
}

const invoiceHeader = ({ invoiceData, businessData, businessLogo, isgst = false }) => {

    const invoiceBusinessDataPDF = () => {
        const { invoicegstin, invoicetype } = invoiceData
        // const isgst = invoicetype === 'gstinvoice'
        const { gSTData = {} } = businessData
        const { businessname, address = '', mobile = '', email = '' } = (gSTData?.[invoicegstin] ?? {})
        const addressWithTitle = address || businessData.businessaddress || ''
        const addresssplit = (!!addressWithTitle) ? (addressWithTitle + '').split('\n') : []
        const addresssArr = (addresssplit.length) ? (((addresssplit.length > 2) || ((addressWithTitle + '').length > 90)) ? splitIntoLines((addressWithTitle + '').replaceAll('\n', ' '), 90) : addresssplit) : []
        const newaddress = addresssArr.map((line, ind) => [writeTextForPDF(line)])
        const newmobile = mobile || businessData.businessphone || ''

        const logo = businessLogo ? [{
            image: businessLogo,
            width: 40,
            // height: 144,
            // absolutePosition: { x: 480, y: 160 },
            alignment: 'left',
        }] : []

        const businessDataTable = {
            table: {
                widths: ['*'],
                body: [
                    [
                        {
                            columns: [
                                ...logo,
                                {
                                    table: {
                                        widths: ['*'],
                                        body: [
                                            [writeTextForPDF(businessname ?? businessData.businessname ?? '', { fontSize: 12, bold: true, fillColor: 'lightblue' }),],
                                            ...newaddress,
                                        ]
                                    },
                                    layout: {
                                        hLineWidth: function (i, node) {
                                            return 0
                                        },
                                        vLineWidth: function (i, node) {
                                            return 0
                                        }
                                    }
                                },
                            ]
                        }
                    ],
                    [{
                        text: `${newmobile ? 'Mobile : ' + newmobile : ''}${email ? `${newmobile ? ' | ' : ''}Email : ` + email : ''}`,
                    }],
                    ...(isgst ? [[{
                        text: 'GSTIN : ' + invoicegstin, bold: true
                    }]] : [])
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    return ((i === 0) || (i == node.table.body.length)) ? 1 : 0;
                },
            }
        }

        return {
            ...businessDataTable,
            alignment: 'center',
        }
    }

    const invoiceDataPDF = () => {
        const { by = '', invoicenumber = '', timestamp = '', ots = '', oid = '' } = invoiceData

        return {
            table: {
                widths: ['*'],
                body: [[{
                    columns: [
                        { text: (isgst ? 'Invoice No : ' : 'No : ') + invoicenumber, bold: true },
                        { text: 'Date : ' + getFormatedDate('dd-mm-yyyy', timestamp) + '\t \t', alignment: 'right', bold: true },
                    ]
                }]]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return (i === 0) ? 0 : 1;
                },
            }
        }
    }

    const invoiceCustomerDataPDF = () => {
        const { invoiceCustomerData = {}, invoicegstin = '', customerAdditionalData = {} } = invoiceData
        const { customername, customermobile, customergst, customeraddress, } = invoiceCustomerData
        const customeraddressWithTitle = !!customeraddress ? ('Address: ' + customeraddress) : ''
        const customeraddresssplit = (!!customeraddressWithTitle) ? (customeraddressWithTitle + '').split('\n') : []
        const newcustaddresssArr = (customeraddresssplit.length) ? (((customeraddresssplit.length > 3) || ((customeraddressWithTitle + '').length > 90)) ? splitIntoLines((customeraddressWithTitle + '').replaceAll('\n', ' '), 90) : customeraddresssplit) : []
        const newcustomeraddress = newcustaddresssArr.map((line, ind) => [writeTextForPDF(line)])
        const emptylineslen = !!customeraddress ? (newcustomeraddress.length ? (3 - newcustomeraddress.length) : 1) : 1
        const emptylines = Array.from({ length: (emptylineslen) }, () => '').map(() => [' '])

        return {
            table: {
                widths: ['*'],
                body: [
                    [{ text: 'Customer Details: ', fillColor: 'lightblue' }],
                    [writeTextForPDF((customername || 'Cash*'), { fontSize: 10, bold: true })],
                    ...newcustomeraddress,
                    ...emptylines,
                    ...((isgst && customergst) ? [[{ text: `GSTIN : + ${customergst}     | State Code : ${(invoicegstin + '').substring(0, 2)} (${stateList[(invoicegstin + '').substring(0, 2)]})` }]] : []),
                    ...(!!customermobile ? [[{ text: 'Phone No: ' + (customermobile || ' '), bold: true }]] : []),
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0;
                },
            }
        }
    }

    return {
        stack: [
            invoiceBusinessDataPDF(),
            invoiceDataPDF(),
            invoiceCustomerDataPDF(),
        ]
    }
}

const invoiceFooter = ({ receiptSettings = {}, invoiceData, businessData }) => {
    const invoiceFooterDecleration = () => {
        const { decleration = '' } = receiptSettings
        return {
            stack: [
                {
                    text: 'Decleration :',
                    // italics: true, 
                    bold: true
                },
                {
                    text: decleration ?? ''
                }
            ],
            alignment: 'left',
        }
    }

    const invoiceFooterBankDetails = () => {
        const { bankid, invoicegstin = '' } = invoiceData
        const gstBankData = (invoicegstin && getLenOfObjBool((businessData?.gSTData ?? {}))) ? businessData?.gSTData?.[invoicegstin] : ({ bankname: '', ifsc: '', accno: '', upi: '' })
        const { bankname = '', ifsc = '', accno = '', upi = '' } = gstBankData
        return {
            stack: [
                {
                    text: 'Bank Details',
                    bold: true
                },
                'Name :' + bankname,
                // 'Account Name :' + accname,
                'Account Number:' + accno,
                'IFSC Code:' + ifsc,
                'UPI ID: ' + upi,
            ],
        }
    }

    const invoiceFooterCustomerSignatory = () => {
        return {
            text: 'Customer\'s Seal and Signature',
            alignment: 'left',
        }
    }

    const invoiceFooterBusinessSignatory = () => {
        const { gSTData = {} } = businessData
        return {
            text: `For ${gSTData?.[invoiceData?.invoicegstin || '-']?.businessname ?? businessData.businessname}\n\n\n\n\nAuthorised Signatory`,
            alignment: 'right',
        }
    }

    return ({
        table: {
            widths: ['*', "*"],
            body: [
                [
                    invoiceFooterBankDetails(),
                    invoiceFooterBusinessSignatory(),
                ],
            ]
        }
    })
}

const invoiceTaxSummary = ({ items, invoiceSummary, isigst = false }) => {
    const { invoiceratebasedtax, invoicetotaltax } = getLenOfObjBool(invoiceSummary) ? invoiceSummary : invoiceSummaryCalc(items)
    const getTaxArray = () => {
        return objReduce(invoiceratebasedtax, (acc, taxrate) => {
            const { cgst, sgst, gst, totaltax, taxableamount } = invoiceratebasedtax[taxrate]
            const taxlines = (isigst ? [1] : [2, 2]).reduce((acc, crr) => [...acc, { text: numToFixed2(taxrate / crr) + '%', alignment: 'center' }, { text: numToFixed2(totaltax / crr), alignment: 'right' },], [])
            return [
                ...acc,
                [
                    { text: taxrate + '%', alignment: 'center' },
                    { text: taxableamount, alignment: 'right' },
                    ...taxlines,
                    { text: numToFixed2(totaltax), alignment: 'right' },
                ]
            ]
        }, [])
    }

    const widths = isigst ? ['*', '*', '*', '*', '*'] : ['*', '*', '*', '*', '*', '*', '*']
    const headerstyle = { alignment: 'center', bold: true, fillColor: 'lightblue' }
    const header1 = (isigst ? ['Integrated Tax'] : ['Central Tax', 'State Tax']).reduce((acc, crr) => [...acc, { text: crr, colSpan: 2, ...headerstyle }, {}], [])
    const header2 = (isigst ? [1] : [1, 2]).reduce((acc,) => [...acc, { text: 'Rate %', ...headerstyle }, { text: 'Amount', ...headerstyle }], [])

    const totaltaxempty = Array.from({ length: (isigst ? 2 : 4) }, () => '').map(() => '')

    const rowSpan = 2
    return {
        table: {
            headerRows: 2,
            widths,
            body: [
                [
                    { text: 'Tax Rate', rowSpan, ...headerstyle },
                    { text: 'Taxable Value', rowSpan, ...headerstyle },
                    ...header1,
                    { text: 'Total Tax Amount', rowSpan, ...headerstyle },
                ],
                [
                    {},
                    {},
                    ...header2,
                    {},
                ],
                ...getTaxArray(),
                [{ text: 'Total', alignment: 'right', bold: true, colSpan: (isigst ? 4 : 6), margin: [0, 0, 10, 0] }, ...totaltaxempty, '', { text: invoicetotaltax, bold: true, alignment: 'right' }],
            ]
        },
        margin: [0, 5]
    }
}

const getItemsHeader = ({ isigst = false, isgst = false }) => {
    const sty = { bold: true, alignment: 'center', fillColor: 'lightblue' }
    if (!isgst)
        return [[
            { text: 'S.No', ...sty, fontSize: 9 },
            { text: 'Particulars', ...sty },
            { text: 'Qty', ...sty },
            { text: 'Rate', ...sty },
            { text: 'Total', ...sty },
        ]]
    const gstheaderdata = (isigst ? ['IGST'] : ['CGST', 'SGST'])
    const gstHeader1 = gstheaderdata.reduce((acc, crr) => [...acc, { text: crr, colSpan: 2, ...sty }, {}], [])
    const gstHeader2 = gstheaderdata.reduce((acc) => [...acc, { text: 'Rate %', ...sty }, { text: 'Amount', ...sty }], [])

    const header = [
        [
            { text: 'S.\nNo', rowSpan: 2, ...sty },
            { text: '\nDescription', rowSpan: 2, ...sty },
            { text: 'HSN/\nParts Code', rowSpan: 2, ...sty },
            { text: '\nQty', rowSpan: 2, ...sty },
            { text: '\nRate', rowSpan: 2, ...sty },
            ...gstHeader1,
            { text: 'Rate\n(Inc.GST)', rowSpan: 2, ...sty },
            { text: '\nTotal', rowSpan: 2, ...sty }
        ],
        [
            {},
            {},
            {},
            {},
            {},
            ...gstHeader2,
            {},
        ]
    ]

    return [...header]
}

const getItemLine = ({ sn, itemname, hsncode, quantity, rate, gstrate, gst, price, total, fill = ' ', isgst = false, isigst = false }, style = {}) => {
    const alignment = 'right'
    if (!isgst)
        return [
            { text: sn, alignment },
            { ...(itemname ? writeTextForPDF(itemname, style) : { text: fill }) },
            { text: quantity ? numToIfFixed3(quantity) : fill, alignment },
            { text: price ? numToIfFixed2(price) : fill, alignment },
            { text: total ? numToIfFixed2(total) : fill, alignment }
        ]
    const gstRows = (isigst ? [1] : [2, 2]).reduce((acc, crr) => [...acc, { text: gstrate ? numToIfFixed2(gstrate / crr) + '%' : fill, alignment }, { text: Number(gst) ? numToIfFixed2(gst / crr) : fill, alignment },], [])
    return [
        { text: sn, alignment },
        { ...(itemname ? writeTextForPDF(itemname, style) : { text: fill }) },
        { text: hsncode ?? fill },
        { text: quantity ? numToIfFixed3(quantity) : fill, alignment },
        { text: rate ? numToIfFixed2(rate) : fill, alignment },
        ...gstRows,
        { text: price ? numToIfFixed2(price) : fill, alignment },
        { text: total ? numToIfFixed2(total) : fill, alignment }
    ]
}

const getAmountInWords = ({ invoiceSummary = {}, items = [] }, { isgst = false }) => {
    const { invoicegrosstotal, invoicetotaltax } = getLenOfObjBool(invoiceSummary) ? invoiceSummary : invoiceSummaryCalc(items)

    return {
        table: {
            widths: ['*'],
            body: [
                [{ text: 'Total Amount In Words : ', fillColor: 'lightblue' }],
                [{ text: iNRWords(invoicegrosstotal) + '\n', bold: true, fontSize: 11 }],
                ...(isgst ? [[{ text: 'Total Tax Amount In Words : ', fillColor: 'lightblue', bold: true, }]] : []),
                ...(isgst ? [[{ text: iNRWords(invoicetotaltax) + '\n' }]] : []),
            ]
        }
    }

}

const getItemFooter = ({ items = [], invoiceSummary = {} }, { isgst }) => {
    const { invoicetemptaxableamount, invoicetotaltax, invoicetotal, invoiceroundoffdiff, invoicegrosstotal } = getLenOfObjBool(invoiceSummary) ? invoiceSummary : invoiceSummaryCalc(items)

    const amountInWords = getAmountInWords({ items, invoiceSummary }, { isgst })

    const totalReport = {
        table: {
            body: [
                ...(isgst ? [[{ text: 'Taxable' }, { text: invoicetemptaxableamount }]] : []),
                ...(isgst ? [[{ text: 'Total Tax' }, { text: invoicetotaltax }]] : []),
                [{ text: 'Total' }, { text: invoicetotal }],
                [{ text: 'Round Off : ' }, { text: [invoiceroundoffdiff > 0 ? '+' : '', invoiceroundoffdiff] }],
                [{ text: 'Total : ', fontSize: 11, bold: true }, { text: invoicegrosstotal, fontSize: 11, bold: true }]
            ]
        },
        alignment: 'right'
    }

    return {
        table: {
            widths: ['*', 'auto'],
            body: [[amountInWords, totalReport]]
        }
    }
}

const planItems = ({ items = [], sno = 0, defaultUnits, isigst = false, isgst = false }, { invoiceData, businessData, businessLogo, softInvoiceItems }, { invoicefooterreq = true, groupitembycat = false }) => {
    let itemSizeInPage = isgst ? (invoicefooterreq ? itemsSizeWithFooter : itemsSizeWithOutFooter) : itemsSizeNonGST
    {
        let linealterationcount = 0, linealterationcountMid = 0
        if (invoiceData.invoicesaletype === 'credit') {
            linealterationcount -= 2
            if (!!invoiceData?.invoicePayment?.paid) linealterationcount -= 1
        }
        if (invoiceData?.invoicePayment?.other || invoiceData?.invoicePayment?.shipping) linealterationcount -= 1

        if (!!!invoiceData?.invoiceCustomerData?.customeraddress) {
            linealterationcount -= -3
            linealterationcountMid -= -3
        }
        if (!!!invoiceData?.invoiceCustomerData?.customermobile) {
            linealterationcount -= -1
            linealterationcountMid -= -1
        }
        itemSizeInPage = { ...itemSizeInPage, single: itemSizeInPage.single - (-linealterationcount), mid: itemSizeInPage.mid - (-linealterationcountMid), last: itemSizeInPage.last - (-linealterationcount) }
    }

    const len = items.length
    if (!len) return []

    let pages = [], currentPage = [], body = [], occupiedLines = 0, remainingLines = []

    const updatePage = () => {
        const widths = isgst ? (isigst ? [15, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : [15, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']) : [20, '*', 25, 40, 60]
        currentPage.push({
            table: {
                widths,
                body: [
                    ...getItemsHeader({ isigst, isgst }),
                    ...body
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    if ((i === 0) || (i === node.table.body.length))
                        return 1;
                    return 0;
                },
            },
            style: 'tableStyle'
        })
        pages.push(currentPage)
        body = []
        currentPage = []
        occupiedLines = 0
    }

    const newItems = groupitembycat ? items.sort((a, b) => {
        const ac = ((a.category ?? '') + '').trim().toLowerCase()
        const bc = ((b.category ?? '') + '').trim().toLowerCase()
        return (ac > bc ? 1 : (ac < bc ? -1 : 0))
    }) : [...items]
    let pastcatname = ''
    for (let i = 0; i < len; i++) {
        try {
            const item = newItems[i]
            const crrcatname = (item.category ?? '').trim()
            const { name, des, hsncode, gstrate, cessrate, quantity, price, unitid, discount, mrp, isservice, pcode } = item
            const itemname = (name + `${des ? `\n${des}` : ''}${pcode ? `\n${pcode}` : ''}` + '').trim()
            const unitname = defaultUnits?.[unitid]?.unitname ?? ''
            const totaltaxrate = numToFixed(gstrate, cessrate)
            const rate = getDiscountedPrice(getRateOnPrice(getDiscountedPrice(price, discount), totaltaxrate))
            const total = getPriceOnRateAndTax(rate * quantity, numToIfFixed2(gstrate, cessrate))
            const taxableamount = Number(rate * quantity).toFixed(2) //total taxable amount for single item
            const gst = getTaxOnRate(taxableamount, gstrate)

            if (currentPage.length === 0) {
                // if (pages.length !== 0) currentPage.push({ text: '', pageBreak: 'before' })
                currentPage.push({ ...invoiceHeader({ invoiceData, businessData, businessLogo, isgst }), ...((pages.length !== 0) ? { pageBreak: 'before' } : {}) })
            }

            if ((groupitembycat) ? (!!crrcatname && (crrcatname !== pastcatname)) : false) {
                pastcatname = crrcatname
                const catsplitLine = splitIntoLines(crrcatname, (itemSizeInPage?.namesize ?? 15))
                body.push(getItemLine({ itemname: (crrcatname + ' : '), isgst, isigst }, { bold: true, underline: true }))
                occupiedLines -= -catsplitLine.length
            }

            const itemnameSplitLine = splitIntoLines(itemname, (itemSizeInPage?.namesize ?? 15))
            const itemnameSplitLineLen = itemnameSplitLine.length
            const predictLenght = occupiedLines - (-itemnameSplitLineLen)
            if (predictLenght <= itemSizeInPage.mid) {
                body.push(getItemLine({ sn: i - (-1) - (-sno), itemname, gst, gstrate, hsncode, price, quantity, rate, total, isgst, isigst }))
                occupiedLines -= -itemnameSplitLineLen
            }
            else {
                const remainingSpace = itemSizeInPage.mid - occupiedLines
                remainingLines = itemnameSplitLine.splice(remainingSpace - 1, itemnameSplitLineLen)
                const nowItemName = itemnameSplitLine.join('\n')
                body.push(getItemLine({ sn: i - (-1) - (-sno), itemname: nowItemName, gst, gstrate, hsncode, price, quantity, rate, total, isgst, isigst }))
                occupiedLines -= -remainingSpace
            }

            if ((occupiedLines >= itemSizeInPage.mid)) {
                updatePage()
                if (remainingLines) {
                    body = [...remainingLines.map(line => isgst ? (isigst ? [{}, { text: writeTextForPDF(line) }, {}, {}, {}, {}, {}, {}, {}] : [{}, { text: writeTextForPDF(line) }, {}, {}, {}, {}, {}, {}, {}, {}, {}]) : [{}, { text: writeTextForPDF(line) }, {}, {}, {}]
                    )]
                    occupiedLines = remainingLines.length
                    remainingLines = []
                }
            }

        } catch (error) {
            console.error(error);
            throw error
        }
    }

    const freeLines = ((itemSizeInPage.last - occupiedLines))
    const emptyline = isgst ? (isigst ? [' ', ' ', ' ', ' ', ' ', ' ', '  ', '  ', '  '] : [' ', ' ', ' ', ' ', ' ', ' ', '  ', '  ', '  ', '  ', '  ']) : [' ', ' ', ' ', ' ', ' ']
    if (freeLines >= 0) {
        const emptylinecount = freeLines - -1
        Array.from({ length: emptylinecount }, () => '').forEach(() => body.push([...emptyline]))
    }
    else {
        if (occupiedLines !== 0) {
            Array.from({ length: (itemSizeInPage.mid - occupiedLines) }, () => '').forEach(() => body.push([...emptyline]))
            updatePage()
        }
        Array.from({ length: itemSizeInPage.last }, () => '').forEach(() => body.push([...emptyline]))
    }

    {
        //final cost report
        const { invoicePayment = {}, invoiceCustomerData } = invoiceData
        const invoiceSummary = invoiceSummaryCalc(items, invoicePayment)
        const { invoicetemptaxableamount, invoicetotaltax, invoicetotal, invoiceroundoffdiff, invoicegrosstotal } = invoiceSummary
        const { other = 0, shipping = 0, paid = 0, discount = 0, getCreditBalance = 0 } = invoicePayment
        let borderlinecheckinclude = 0
        // let borderlinecheckinclude = (!!Number(discount) ? 1 : 0) + (!!Number(invoiceroundoffdiff) ? 1 : 0) + (invoiceData.invoicesaletype === 'credit' ? (2 + (paid > 0 ? 1 : 0)) : 0)

        if (!!Number(other) || !!Number(shipping)) {
            borderlinecheckinclude += 1
            const ttl = numToIfFixed2(Number(other) + Number(shipping))
            const txt = (!!Number(shipping) ? 'Shipping' : '') + (!!Number(other) ? ((!!Number(shipping) ? ' & ' : '') + 'Other') : '') + ' Charges : '
            body.push([{ text: txt, alignment: 'right', colSpan: 4 }, {}, {}, {}, { text: ttl, alignment: 'right' }])
        }

        if (!!Number(discount)) {
            borderlinecheckinclude += 1
            body.push([{ text: 'Discount : ', alignment: 'right', colSpan: 4 }, {}, {}, {}, { text: discount, alignment: 'right' }])
        }

        if (isgst) {
            const isigstfield = (isigst ? [1] : [2, 2]).reduce((acc, crr) => [...acc, { text: numToIfFixed2(invoicetotaltax / crr) }, {},], [])
            body.push([{}, { text: 'Sub Total :' }, {}, {}, { text: invoicetemptaxableamount }, {}, ...isigstfield, { text: invoicetotal }])
        }
        else {
            if (Number(invoiceroundoffdiff) !== 0) {
                borderlinecheckinclude += 1
                body.push([{ text: 'Round Off :', alignment: 'right', margin: [0, 0, 10, 0], colSpan: 4, fillColor: 'lightblue' }, {}, {}, {}, { text: (invoiceroundoffdiff > 0 ? '+' : '', invoiceroundoffdiff), alignment: 'right', fillColor: 'lightblue' }])
            }
            borderlinecheckinclude += 1
            body.push([{ text: 'Total :', alignment: 'right', margin: [0, 0, 10, 0], bold: true, fontSize: 13, colSpan: 4, fillColor: 'lightblue' }, {}, {}, {}, { text: invoicegrosstotal, alignment: 'right', bold: true, fontSize: 13, fillColor: 'lightblue' }])
        }

        if ((invoiceData.invoicesaletype === 'credit')) {
            if (paid > 0) {
                borderlinecheckinclude += 1
                body.push([{ text: 'Paid Amount : ', alignment: 'right', colSpan: 4, }, {}, {}, {}, { text: paid, alignment: 'right' }])
            }
            borderlinecheckinclude += 2
            const oldbal = invoiceCustomerData?.totalbal || 0
            body.push([{ text: 'Old Balance : ', alignment: 'right', colSpan: 4, }, {}, {}, {}, { text: oldbal || 0, alignment: 'right' }])
            const totalbalance = numToIfFixed2(Number((invoicegrosstotal - paid) - (-oldbal)))
            body.push([{ text: 'Total Balance : ', alignment: 'right', colSpan: 4, }, {}, {}, {}, { text: totalbalance, alignment: 'right', bold: true }])
        }

        const widths = isgst ? (isigst ? [15, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'] : [15, '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']) : [20, '*', 25, 40, 60]

        if (currentPage.length === 0) {
            currentPage.push({ ...invoiceHeader({ invoiceData, businessData, businessLogo, isgst }), ...((pages.length !== 0) ? { pageBreak: 'before' } : {}) })
        }

        currentPage.push({
            table: {
                widths,
                body: [
                    ...getItemsHeader({ isigst, isgst }),
                    ...body
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    if ((i === 0))
                        return 1;
                    if (invoicefooterreq ? false : (i === node.table.body.length))
                        return 1;
                    const lastbeforecount = node.table.body.length - borderlinecheckinclude
                    if (i >= lastbeforecount)
                        return 1;
                    return 0;
                },
            },
            style: 'tableStyle'
        })

        if (isgst)
            currentPage.push(getItemFooter({ invoiceSummary }, { isgst }))

        pages.push(currentPage)
    }

    return pages
}

const planPDFByPartsAndService = ({ invoiceData, businessData, businessLogo, defaultUnits, receiptSettings }, invoiceFormatSetting) => {
    const isseperateservice = (invoiceFormatSetting?.seperateforservice ?? false)
    const forcegstformat = (invoiceFormatSetting?.alwaysgst ?? false)
    const issummary = (invoiceFormatSetting?.summary ?? false)
    const { softInvoiceItems = [], invoicegstin, invoiceCustomerData, invoicetype } = invoiceData
    const invoicestatecode = (invoicegstin + '').substring(0, 2)
    const customerstatecode = invoiceCustomerData.customergst ? (invoiceCustomerData.customergst + '').substring(0, 2) : ''
    const isgst = (invoicetype === 'gstinvoice') || forcegstformat
    const isigst = customerstatecode ? (invoicestatecode !== customerstatecode) : false

    const { serviceItems = [], partsItems = [] } = (isseperateservice) ? softInvoiceItems.reduce((acc, crr) => {
        if (crr.isservice ?? false)
            return { ...acc, serviceItems: [...acc.serviceItems, crr] }
        return { ...acc, partsItems: [...acc.partsItems, crr] }
    }, { serviceItems: [], partsItems: [] })
        : { partsItems: [...softInvoiceItems] }

    let pdfPages = []

    const invoicefooterreq = isgst ? !isseperateservice : false

    if (partsItems.length) {
        pdfPages = [...pdfPages, ...planItems({ items: partsItems, defaultUnits, isigst, isgst }, { invoiceData, businessData, businessLogo }, { invoicefooterreq })]
        if (invoicefooterreq)
            pdfPages.push(invoiceFooter({ receiptSettings, businessData, invoiceData }))
    }

    if (serviceItems.length) {
        if (partsItems.length)
            pdfPages.push({ pageBreak: 'before', text: '' })
        pdfPages = [...pdfPages, ...planItems({ items: serviceItems, defaultUnits, isigst, isgst }, { invoiceData, businessData, businessLogo }, { invoicefooterreq })]
    }

    if (!issummary) return pdfPages

    const invoiceSummary = invoiceSummaryCalc(softInvoiceItems)
    const { invoicetemptaxableamount, invoicetotaltax, invoicetotal, invoiceroundoffdiff, invoicegrosstotal } = invoiceSummary
    const { invoicePayment } = invoiceData
    const paid = Number(invoicePayment.paid)
    const bal = Number(invoicegrosstotal - paid).toFixed(2)

    pdfPages.push({ ...invoiceHeader({ invoiceData, businessData, businessLogo, isgst }), pageBreak: 'before' })
    pdfPages.push({ text: 'Invoice Summary', alignment: 'center', fontSize: 12, bold: true, underline: true, margin: 5 })
    pdfPages.push({
        columns: [
            {},
            {
                stack: [
                    ...(isgst ? [{ text: 'Taxable Amount : ', fontSize: 12 }] : []),
                    ...(isgst ? [{ text: 'Total Tax : ', fontSize: 12 }] : []),
                    // 'Total : ',
                    { text: 'Total : ', fontSize: 12 },
                    { text: 'Round Off : ', fontSize: 12 },
                    { text: 'Total : ', fontSize: 13, bold: true },
                    ...(paid ? [{ text: 'Advance : ', fontSize: 12, bold: true }] : []),
                    ...(paid ? [{ text: 'Balance : ', fontSize: 14, bold: true }] : []),
                ],
                alignment: 'right'
            },
            {
                stack: [
                    ...(isgst ? [{ text: invoicetemptaxableamount, fontSize: 12 }] : []),
                    ...(isgst ? [{ text: invoicetotaltax, fontSize: 12 }] : []),
                    // invoicetotal,
                    { text: invoicetotal, fontSize: 13 },
                    { text: [invoiceroundoffdiff > 0 ? '+' : '', invoiceroundoffdiff], fontSize: 12 },
                    { text: numToFixed2(invoicegrosstotal), fontSize: 17, bold: true },
                    ...(paid ? [{ text: paid.toFixed(2), fontSize: 12, bold: true }] : []),
                    ...(paid ? [{ text: bal, fontSize: 14, bold: true }] : []),
                ],
                alignment: 'right'
            }
        ],
        margin: [0, 20],
    })

    if (isgst)
        pdfPages.push(invoiceTaxSummary({ invoiceSummary, isigst }))

    pdfPages.push(getAmountInWords({ invoiceSummary }, { isgst }))
    pdfPages.push({ text: ' ', margin: 5 })

    pdfPages.push(invoiceFooter({ businessData, invoiceData, receiptSettings }))


    return pdfPages
}

export const A5PDFInvoice1 = ({
    invoiceData,
    businessData,
    coll = 'sales',
    receiptSettings,
    defaultUnits,
    businessLogo,
    invoiceFormatSetting,
}) => {

    const forcegstformat = invoiceFormatSetting?.alwaysgst ?? false
    const invoicetype = (invoiceData.invoicetype === 'quotation') ? (forcegstformat ? 'estimation' : 'quotation') : invoiceData.invoicetype

    const dd = {
        footer: function (currentPage, pageCount) {
            return invoicePageFooter({ currentPage, pageCount, receiptSettings })
        },
        header: function (currentPage, pageCount, pageSize) {
            return invoicePageHeader({ invoicetype, coll, title: invoiceFormatSetting.title })
        },
        watermark: invoicePageWaterMark({ receiptSettings }),
        content: [...planPDFByPartsAndService({ businessData, businessLogo, invoiceData, defaultUnits, receiptSettings, }, invoiceFormatSetting)],
        defaultStyle: {
            fontSize: 8,
            // margin: 1,
        },
        styles: {
            tableStyle: {
                fontSize: 10,
                bold: true,
            }
        }
    }


    return dd

}
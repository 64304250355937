export const generalItemSettingDefaultData = {
    addby: 'basic',
    cess: false,
    unithierarchy: false,
}

export const itemBarcoceDefaultData = {
    isenabled: true,
    barcodelen: 5,
    barcodechar: 'num',
}

export const pointsItemSettingDefaultData = {
    customerPoint: {
        isenabled: false,
        // pointby: '',
        point: '', //for every 100 rupee how many point are you giving?
    },
    salesManPoint: {
        isenabled: false,
        // pointby: '',
        point: '',
    },
    referalPoint: {
        isenabled: false,
        // pointby: '',
        point: '',
    },
}

export const stockItemSettingDefaultData = {
    lowstock: 10,
    itemwisealert: true,
}

export const addItemFieldsSettingDefaultData = {
    //itemdata
    isonline: false,
    isservice: false,
    israw: false,
    categoryid: true,
    subcategoryid: false,
    itemname: true,
    des: false,
    brandid: false,
    secitemname: false,
    hsncode: true,
    gstin: false,
    gstrate: true,
    cessrate: false,


    //avldata
    avlisonline: false,
    itemcode: true,
    saleunitid: true,
    unithierarchy: false,
    avldes: false,
    sizeid: false,
    colourid: false,
    patternid: false,
    textureid: false,
    margin: false,
    marginasper: false,
    minqty: false,
    maxqty: false,
    istoken: false,
    gpriority: false,

    //master data
    excludestockalert: false,
    stockalert: false,
    excludecustomerpoint: false,
    customerpoint: false,
    excludereferalpoint: false,
    referalpoint: false,
    excludesalespoint: false,
    salespoint: false,
    isdailypricechange: false,

    //saledata
    batchid: false,
    barcode: true,
    crrstock: false,
    pprice: true,
    saleprice: true,
    mrp: true,
    expiredate: false,
    discount: false,

}

export const addItemFieldGroupSettingsDefaultData = {
    addmultiitem: false,
    addavltype: false,
    addbatch: false,
    masterdata: false,
    pricegroups: false,
}

export const defaultItemSettings = {
    itemGeneralSetting: generalItemSettingDefaultData,
    itemBarcodeSetting: itemBarcoceDefaultData,
    pointsSetting: pointsItemSettingDefaultData,
    stockSetting: stockItemSettingDefaultData,
    addItemFieldsSetting: addItemFieldsSettingDefaultData,
    addItemFieldGroupSetting: addItemFieldGroupSettingsDefaultData
}
export const generalAppSettingsDefaultData = {
    apptitle: '',
}

export const labelSettingDefaultData = {
    isqr: false,
    title: '',
    multilinename: '',
    height: 20,
    width: 30,
    gap: 0,
    columns: 1,
    bheight: 8,
    bwidth: 1,
    displayvalue: true,
    rotate: false,
    printprice: true,
    printmrp: false,
    printqty: true,
    quantity: '',
    printunit: true,
    printdate: false,
    printuseby: false,
    usebydays: 30,
    printsize: false,
    printsizedes: false,
    printpattern: false,
    printunitinshort: true,
    includepriceinbar: false,
    includeqtyinbar: false,
    convertpricebyquantity: false,
    lic: '',
    adddata: '',
    boldall: false,
    fontratio: 7,
    sfontincby: 0,
}

export const defaultAppSettings = {
    labelSetting: labelSettingDefaultData,
    generalSetting: generalAppSettingsDefaultData,
}

import { getFormatedDate } from "../../../../General/GetDateTime"
import { iNRWords } from "../../../../General/numberToWords"
import { numToFixed2 } from "../../../../General/numManipulation"
import { pDFPageNumber, writeTextForPDF } from "../../PDFFunctions"

export const ProfileLedgerPDFContent = (data) => {
    const {
        ledger = [], openingBalance, closingBalance, currentBalance, totalcredit, totaldebit, customer, range = '', invoicegstin = '',
        businessData, businessLogo,
    } = data

    const businessHeader = () => {
        const { gSTData = {} } = businessData
        const igstin = invoicegstin || Object.keys(gSTData)?.[0] || ''
        const { businessname = businessData.businessname, address = businessData.businessaddress, mobile = '' } = (gSTData?.[invoicegstin] ?? {})
        return {
            columns: [
                {
                    image: businessLogo,
                    width: 40,
                    height: 40,
                },
                {
                    stack: [
                        writeTextForPDF(businessname, { fontSize: 16, bold: true }),
                        writeTextForPDF(address, { fontSize: 12 }),
                        { text: mobile, fontSize: 12 },
                    ],
                    fontSize: 12,
                    alignment: 'center',
                },
            ],
            margin: [0, 0, 0, 10],
            // widths: [60, '*', 60],
        }
    }

    const customerHeader = () => {
        return {
            stack: [
                writeTextForPDF(customer.customername, { fontSize: 16, bold: true }),
                writeTextForPDF(customer.customeraddress, { fontSize: 12 }),
                writeTextForPDF(customer.customermobile, { fontSize: 12, bold: true }),
                { text: customer.customergst, fontSize: 12, bold: true },
            ],
            alignment: 'center',
            margin: [0, 0, 0, 10],
        }
    }

    const ledgerTable = () => {
        return {
            table: {
                headerRows: 1,
                widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto'],
                body: [
                    //header
                    [
                        { text: 'Date', bold: true },
                        { text: 'Particulars', bold: true },
                        { text: 'Vch Type', bold: true },
                        { text: 'Vch No', bold: true },
                        { text: 'Debit', bold: true },
                        { text: 'Credit', bold: true },
                        { text: 'Balance', bold: true },
                    ],
                    //openingBalance
                    [
                        {},
                        { text: 'Opening Balance', bold: true },
                        {},
                        {},
                        {},
                        { text: '', alignment: 'right' },
                        { text: numToFixed2(openingBalance), alignment: 'right', bold: true },
                    ],
                    //table Body
                    ...ledger.map((item, index) => [
                        { text: item.date, alignment: 'center' },
                        { text: item.particulars },
                        { text: item.vchtype },
                        { text: item.vchno },
                        { text: item.debit, alignment: 'right' },
                        { text: item.credit, alignment: 'right' },
                        { text: numToFixed2(item.balance), alignment: 'right' },
                    ]),
                    //total credit and debit
                    [
                        {},
                        { text: '\nTotal', bold: true, alignment: 'right' },
                        {},
                        {},
                        { text: '___________\n' + numToFixed2(totaldebit), alignment: 'right', bold: true },
                        { text: '___________\n' + numToFixed2(totalcredit), alignment: 'right', bold: true },
                        {},
                    ],
                    // closingBalance
                    ...(!!Number(closingBalance) ? [
                        [
                            {},
                            { text: 'Closing Balance', bold: true },
                            {},
                            {},
                            {},
                            { text: '', alignment: 'right' },
                            { text: numToFixed2(closingBalance), alignment: 'right', bold: true },
                        ],
                    ] : []),
                ],
            },
            layout: {
                hLineWidth: function (i, node) {
                    if (i === 0)
                        return 1
                    if (i === node.table.headerRows)
                        return 1;
                    if (i === node.table.body.length)
                        return 1;
                    return 0;
                },
                vLineWidth: function () {
                    return 1;
                },
            }
        }
    }

    const content = () => [
        businessHeader(),
        { text: '________________________________', bold: true, margin: [0, 0, 0, 10], alignment: 'center' },
        customerHeader(),
        {
            text: range ?? '',
            fontSize: 12,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: 'center',
        },
        ledgerTable(),
        ...(!!Number(closingBalance) ? [
            {
                text: `Current Balance (${getFormatedDate('dd-mmm-yy')}) : ${numToFixed2(currentBalance)}`,
                fontSize: 12,
                bold: true,
                alignment: 'center',
                margin: [0, 40, 0, 10],
            },
            {
                text: iNRWords(currentBalance),
                fontSize: 12,
                bold: true,
                alignment: 'center',
            }
        ] : []),
        { text: '================ ==============', bold: true, margin: [0, 20, 0, 10], alignment: 'center' },
    ]

    return {
        header: function () {
            return { text: 'Ledger', alignment: 'center', bold: true, fontSize: '18' }
        },
        footer: function (currentPage, pageCount) {
            return pDFPageNumber(currentPage, pageCount, { bold: true })
        },
        content: content(),
    }
}
